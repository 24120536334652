header{
    background-color: #061842;
    font-family: "Ubuntu", sans-serif;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100vw;
}
main {
    flex-grow: 1;
}
.c-logo{
    width: 30px;
    border-radius: 50%;
}
.c-name{
    padding: 16px;
    font-size: 40px;
    font-weight: 600;
    color: aliceblue;
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.auth-box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin: 0 8px;
}

.admin-auth{
    font-size: 20px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    background: none;
    border: none;
    color: aliceblue;
    cursor: pointer;
}

@media (max-width: 768px) {
    .c-name{
        font-size: 20px;
    }

    .nav-bar{
        background-color: transparent;
        border: none;
        font-size: 18px;
    }

    .c-logo{
        width: 18px;
    }
}