
/*
   carousel blocks
*/
.carousel-container{
    width: 400px;
    height: 400px;
}
.number-cards{
    width: 400px;
    height: 400px;
    background-color: #0d4c83;
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 80pt;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide-cards{
    width:100%;
    height: 100%;
    background-color: #0d4c83;
    color: #ffffff;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 80pt;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boom{
    display: none;
}