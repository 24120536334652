.body-wrapper{
  margin: 0;
  display: flex;
  flex-direction: column;
}

.app-main{
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
}

body{
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  margin-top: 78px;
}

.nav-box{
  padding: 12px;
  font-family: "Chakra Petch", sans-serif;
  display: flex;
  height: 30px;
}
.nav-button{
  color: #061842;
  font-weight: 600;
  font-size: 20px;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin: 0 16px;
  align-items: center;
}
.button-label{
    margin-right: 8px;
}

.sub-nav{
  height: 0px;
  overflow: hidden;
  background-color: #ecf2f7;
  width: 100vw;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  top: 124px;
  left: 0;
  transition:height 0.5s;
  transition-timing-function: ease-out;
}

.show-nav{
  display: flex;
  height: 200px;
  transition:height 0.5s;
  transition-timing-function: ease-out;
  overflow-y: scroll;
  border-top: 1.5px solid #061842;
  z-index: 9;
}

.app-nav{
  height: 50px;
}

.caret{
  transition: transform 0.5s;
  transition-timing-function: ease;
  transform: rotate(0deg);
}

.inverted{
  transition: transform 0.5s;
  transition-timing-function: ease;
  transform: rotate(180deg);
}

.colored-bottom{
    border-bottom: 3px solid #061842;
}

.bg-image{
  width: 100vw;
  height: calc(100vh - 128px);
  position: fixed;
  z-index: -9;
}

.bg{
  width: 100%;
  height: 100%;
}

main{
  flex-grow: 1;
}
.c-theme{
  font-family: "Ubuntu", sans-serif;
  font-size: 30px;color: #ffffff;
  margin: 40px 0;
  flex-grow: 1;
  text-align: center;
}

.org-theme{
  flex-grow: 1;
}

.page-text{
  display: flex;
}

.solutions{
  font-family: "Ubuntu", sans-serif;
  color: #ffffff;
  font-size: 24px;
  padding: 24px;
  line-height: 24px;
}

.fa-check{
  margin-right: 16px;
}

.sol-unit{
  margin: 16px 0;
  font-size: 40px;
}

.sol-text{
  margin-left: 12px;
}
.nav-show-button{
  display: none;
}
.label-box{
  width: 100%;
  display: flex;
}

.sub-nav-button{
  background: none;
  border: none;
  outline: none;
  height: 40px;
  color: #061842;
  font-size: 20px;
  margin: 8px 16px;
  cursor: pointer;
}
@media (max-width: 768px) {

  .nav-show-button{
    display: inline-flex;
  }

  .show-nav {
    width: 100px;
    height: 300px;
  }

  .nav-box{
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #ffffff;
    height: calc(100vh - 0px);
    z-index: 9;  
    position: fixed;    
  }

  .page-text{
    flex-direction: column;
  }

  .bg-image {
    height: 100vh;
  }

  .sol-unit{
    font-size: 24px;
  }

  .dots-container{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  .dots {
    width: 50%;
  }
 
  nav {
    display: none;
    background-color: transparent;
    height: 0;
    width: 100vw;
    margin-top: 55px;
  }

  
 
  .slide-nav{
    display: flex;
    width: 300px;
    animation: slide-right 0.6s ease;
    height: 0;
  } 

  .hide-nav{
    animation: slide-left 0.6s ease;
    display: flex;
    height: 0;
    margin-top: 55px;
    margin-left: -350px;
  }

  .button-label{
    flex-grow: 1;
    text-align: left;
  }

  .nav-button{
    color: #061842;
    font-weight: 600;
    font-size: 20px;
    background: none;
    width: 250px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 16px 0;
    align-items: center;
  }



  
  .show-nav, .sub-nav{
    position: relative;
    outline: none;
    top: 0;
    width: 300px;
    background-color: #ecf2f7;
    cursor: pointer;
  }
  

  @keyframes slide-right {
    from {
      margin-left: -350px;
    }
    to {
      margin-left: 0;
    }
  }

  @keyframes slide-left {
    from {
      margin-left: 0;
    }
    to {
      margin-left: -300px;
    }
  }
  
}

