footer{
    background-color: #061842;
    font-family: "Ubuntu", sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-container{
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100vw;
}

.footer-name{
    font-size: 24px;
    text-align: center;
}

.c-dets{
    padding: 16px;
}

.dets-caption{
    font-size: 20px;
    color: #ffffff;
    font-family: "Chakra Petch", sans-serif;
    font-weight: 600;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #ffffff;
}

.dets-points{
    font-size: 16px;
    color: #ffffff;
    padding: 8px;
    margin: 8px 0;
}

.det-point {
    margin-left: 8px;
}