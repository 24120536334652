/* Form inputs */
.input-box {
    display: flex;
    width: 85%;
    align-items: center;
    border: 1px solid #061842;
    border-radius: 8px;
    margin: 8px 0;
}

.input-icon {
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    width: 42px;
}

.form-input {
    border: none;
    outline: none;
    padding: 4px;
    flex-grow: 1;
    height: 28px;
}

.input-error{
    border: 1px solid red;
}

.input-status{
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
}

.required{
    color: red;
    font-weight: 600;
}

/* Form container */
.form-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Form */
.auth-form {
    width: 400px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 3px 0px #343434, 0px 2px 1px 0px #343434;
    border-radius: 12px;
}

/* Form submit-button */
.form-submit {
    display: flex;
    width: 85%;
    align-items: center;
    background-color:  #061842;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    height: 36px;
    border: none;
    color: #ffffff;
    font-family: "Ubuntu", sans-serif;
}

.input-error {
    background-color: #ababab;
    color: #ffffff;
}