.left-nav{
    display: flex;
    flex-direction: column;
    width: 200px;
    border-right: 2px solid #2f62c0;
    height: calc(100vh - 100px);
    background-color: #292525;
}

.nav-tab{
    padding: 8px;
    margin: 8px 0;
    text-align: left;
    border: none;
    background-color: transparent;
    font-family: "Ubuntu", sans-serif;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
}

.tab-icon{
    margin-right: 8px;
}

.tab-label{
    font-weight: 600;
}