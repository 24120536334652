.project-header {
    color: #032a5b;
    text-align: center;
    flex-grow: 1;
}

.project-links{
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-button{
    border-radius: 8px;
    padding: 8px;
    background-color: #032a5b;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin: 8px;
}